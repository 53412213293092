.tippy-box {
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 1920px !important;
}
.tippy-content {
  position: relative;
  z-index: 1;
  padding: 10px 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #282828;
}
.tippy-arrow {
  color: #FFFFFF;
}
