@import '../../styles/mixin';

.PolicyHeaderBackButton {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  padding-left: 30px;
  color: #FFFFFF;
  background: url('../../assets/icons/arrow-back-white.svg') no-repeat left;
  cursor: pointer;
  width: fit-content;

  &:hover {
    opacity: .75;
  }
}
.SideWrapper {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.SideWrapperInner {
  grid-column: span 12;

  @include screen-xl {
    grid-column: span 8;
  }
}

.TopSideWrapper {
  grid-column: span 12;
  margin-top: 70px;

  &[data-has-documents] {
    @include screen(xs, sm, md, lg) {
      margin-bottom: 140px;
    }
  }
}

.TopButtonsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  grid-column: span 12;
  z-index: 10;

  @include screen-xl {
    grid-column: span 8;
  }
}

.TopDocumentsCardWrapper {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.TopDocumentsCardWrapperInner {
  grid-column: span 12;


  @include screen-xl {
    grid-column: 9 / span 4;
  }
}

.TopDocumentsCardWrapperInnerContainer {
  position: relative;
  margin: 72px 0 82px;

  @mixin props {
    height: calc(100% + 129px);
    margin: 0;
    padding: 0 20px;
    position: absolute;
    top: -129px;
    z-index: 9;
  }

  @include screen-xl {
    @include props;
  }
}

.TopDocumentsCardWrapperInnerSticky {
  @mixin props {
    position: sticky;
    top: 30px;
    right: 0;
  }

  @mixin props-fullsize {
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 9;
  }

  @include screen-xs {
    @include props-fullsize;
  }

  @include screen-sm {
    @include props-fullsize;
  }

  @include screen-md {
    @include props-fullsize;
  }

  @include screen-lg {
    @include props-fullsize;
  }

  @include screen-xl {
    @include props;
  }
}

.PreloaderWrapper {
  height: 50vh;
  padding: 50px;
}

.TitledContentWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 30px;
  &_RecurrentPayment {
    grid-template-columns: 2fr 1fr 2fr;
  }
}

.TitledContentSwitch {
  display: flex;
  align-items: center;
  margin-top: 6px;
  &__Text {
    padding-left: 8px;
    font-size: 20px;
    font-weight: 500;
    &_Checked {
      font-weight: 700;
    }
  }
}

.InfoList {
  margin-top: 13px;
}

.HistoryTable {
  margin-top: 30px;

  tr {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }

  td {
    &:nth-child(1) {
      grid-column: span 3;
    }

    &:nth-child(2) {
      grid-column: span 7;
    }

    &:nth-child(3) {
      grid-column: span 2;
      justify-content: flex-end;
    }
  }
}

.AdditionalIncomeTable {
  background: #F8F8FA;
  border-radius: 20px;

  tr {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    border-color: #FFFFFF;
  }

  td {
    &:nth-child(1) {
      grid-column: span 2;
    }
    &:nth-child(2) {
      grid-column: span 1;
    }
    &:nth-child(3) {
      grid-column: span 3;
    }
    &:nth-child(4) {
      grid-column: span 3;
    }
    &:nth-child(5) {
      grid-column: span 3;
    }
  }
}

.ClampTable {
  margin-top: 30px;

  tr {
    td {
      &[data-col=status] {
        font-weight: bold;
      }
    }

    &[data-status=DataChangeRequestStatus_ChangeApplied] {
      td {
        &[data-col=status] {
          display: flex;
          flex-flow: row nowrap;
          font-weight: normal;

          &:before {
            content: '\2713';
            margin-right: 7px;
            color: #95c11f;
          }
        }
      }
    }

    &[data-status=DataChangeRequestStatus_RequestDenied] {
      td {
        &[data-col=status] {
          color: #f5515f;
          font-weight: bold;
        }
      }
    }
  }

  td {
    &[data-col=date] {
      width: 25%;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }

    &[data-col=name] {
      width: 50%;

      span {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;

        &[data-rel=subTitle] {
          margin-top: 14px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #828a9f;
        }
      }
    }

    &[data-col=status] {
      width: 25%;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }
  }
}