@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(1);
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  100% {
    opacity: 1;
    transform: scale(1.83);
  }
}

@keyframes pulse {
  // Pulse 1
  0% {
    opacity: 1;
    transition-timing-function: cubic-bezier(0, 0.6, 0.3, 1);
    transform: scale(1.83);
  }

  14% {
    opacity: 1;
    transform: scale(1.38);
  }

  // Pulse 2
  14% {
    opacity: 1;
    transition-timing-function: cubic-bezier(0.5, 0, 0.2, 1);
    transform: scale(1.38)
  }

  38% {
    opacity: 1;
    transform: scale(1.83);
  }

  // Pulse 3
  38% {
    opacity: 1;
    transition-timing-function: cubic-bezier(0, 0.6, 0.3, 1);
    transform: scale(1.83)
  }

  52% {
    opacity: 1;
    transform: scale(1.38);
  }

  // Pulse 4
  52% {
    opacity: 1;
    transition-timing-function: cubic-bezier(0.5, 0, 0.2, 1);
    transform: scale(1.38)
  }

  100% {
    opacity: 1;
    transform: scale(1.83);
  }
}

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1000;
}

.appear {
  animation-fill-mode: forwards;
  animation-duration: 0.7s;
  animation-name: appear;
}

.pulse {
  opacity: 1;
  animation-fill-mode: forwards;
  animation-duration: 2.1s;
  animation-name: pulse;
  animation-iteration-count: infinite;
}

.logo {
  width: 6%;
  object-fit: contain;
  opacity: 0;
}
